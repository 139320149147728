<template>
  <v-container>
    <v-row class="pt-3">
      <v-app-bar
        flat
        dense
        color="transparent"
      >
        <h1 class="text-h5 primary--text">Produtos destaque</h1>
      </v-app-bar>
      <v-col
        cols="12"
        lg="12"
      >
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <SectionTitle title="Escolhidos" />
            <v-virtual-scroll
              :bench="1"
              :items="list"
              :height="390"
              item-height="72"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  link
                  two-line
                  :key="item"
                  @click="removeToItemList(item)"
                >
                  <v-list-item-avatar
                    rounded
                    class="elevation-1"
                  >
                    <v-img
                      v-if="item.product_image"
                      :src="$store.state.imagePath + item.product_image"
                      cover
                      class="text-right"
                    />
                    <v-icon
                      v-else
                      small
                      >fas fa-cog</v-icon
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1 secondary--text">
                      {{ item.product_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-body-2 grey--text text-lighten-3"
                    >
                      {{ item.product_reference }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-col>
          <v-col
            cols="12"
            lg="8"
          >
            <SectionTitle title="Todos os produtos" />
            <v-app-bar
              flat
              color="primary"
              rounded="lg"
              class="mb-4"
            >
              <v-text-field
                v-model="search"
                placeholder="Buscar produto..."
                class="mr-2"
                solo
                rounded
                hide-details
                dense
                flat
              />
              <v-btn
                rounded
                outlined
                dark
                @click="getFilter"
              >
                Buscar
                <v-icon
                  small
                  right
                  >fas fa-search</v-icon
                >
              </v-btn>
            </v-app-bar>
            <v-virtual-scroll
              :bench="1"
              :items="items"
              :height="300"
              item-height="72"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  link
                  two-line
                  :key="item"
                  @click="addToChoosenList(item)"
                >
                  <v-list-item-avatar
                    rounded
                    class="elevation-1"
                  >
                    <v-img
                      v-if="item.product_image"
                      :src="$store.state.imagePath + item.product_image"
                      cover
                      class="text-right"
                    />
                    <v-icon
                      v-else
                      small
                      >fas fa-cog</v-icon
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1 secondary--text">
                      {{ item.product_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-body-2 grey--text text-lighten-3"
                    >
                      {{ item.product_reference }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    items: [],
    list: [],
    search: null,
  }),
  methods: {
    getProducts() {
      this.$api
        .get('products/site_list', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getFilter() {
      this.$api
        .post('products/filter_list', { text: this.search })
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getList() {
      this.$api
        .get('site/homes/products', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.list = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addToChoosenList(item) {
      this.$api
        .post(`site/homes`, item)
        .then((res) => {
          item = res.data.data
          this.list.push(item)
          this.removeItem(item)
          // this.getProducts()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeToItemList(item) {
      this.$api
        .post(`site/homes/delete`, item)
        .then(() => {
          this.removeChoosen(item)
          this.items.push(item)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeItem(obj) {
      let idx = 0
      this.items.map((item) => {
        if (item.product_id == obj.product_id) {
          this.items.splice(idx, 1)
        } else idx++
      })
    },
    removeChoosen(obj) {
      let idx = this.list.indexOf(obj)
      this.list.splice(idx, 1)
    },
  },
  created() {
    this.getProducts()
    this.getList()
  },
}
</script>
